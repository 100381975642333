import { render, staticRenderFns } from "./TransactionCallback.vue?vue&type=template&id=475e6ab1&"
import script from "./TransactionCallback.vue?vue&type=script&lang=js&"
export * from "./TransactionCallback.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports